import {Ihistory, Iportfolio, Iroute, Iservice, IservicePrice, Iskill} from "../interface/dataInterface";

export const profilePicFileLink:string = "https://drive.google.com/uc?id=1jcCLuYGibfmmYl6lhxQTR4Wm640hmZNW"

export const languageSkills:Iskill[] = [
    {
        skillTitle : 'فارسی',
        skillPercent : 100,
        skillText : '۱۰۰%'
    },
    {
        skillTitle : 'انگلیسی',
        skillPercent : 60,
        skillText : '۶۰%'
    },
]

export const mainSkills:Iskill[] = [
    {
        skillTitle : 'ReactJs',
        skillPercent : 80,
        skillText : '۸۰%'
    },
    {
        skillTitle : 'NextJs',
        skillPercent : 90,
        skillText : '۹۰%'
    },
    {
        skillTitle : 'Vue.js',
        skillPercent : 70,
        skillText : '۷۰%'
    },
    {
        skillTitle : 'TypeScript',
        skillPercent : 80,
        skillText : '۸۰%'
    },
    {
        skillTitle : 'ExpressJs',
        skillPercent : 70,
        skillText : '۷۰%'
    },
    {
        skillTitle : 'Restful API',
        skillPercent : 90,
        skillText : '۹۰%'
    },
    {
        skillTitle : 'JavaScript',
        skillPercent : 75,
        skillText : '۷۵%'
    },
    {
        skillTitle : 'HTML',
        skillPercent : 100,
        skillText : '۱۰۰%'
    },
    {
        skillTitle : 'CSS',
        skillPercent : 90,
        skillText : '۹۰%'
    },
    {
        skillTitle : 'Sass & Less',
        skillPercent : 65,
        skillText : '۶۵%'
    },
    {
        skillTitle : 'TailwindCSS',
        skillPercent : 90,
        skillText : '۹۰%'
    },
    {
        skillTitle : 'Bootstrap',
        skillPercent : 90,
        skillText : '۹۰%'
    },
    {
        skillTitle : 'Git',
        skillPercent : 80,
        skillText : '۸۰%'
    },
]

export const secondarySkills:Iskill[] = [
    {
        skillTitle : 'Figma',
        skillPercent : 80,
        skillText : '۸۰%'
    },
    {
        skillTitle : 'Adobe XD',
        skillPercent : 65,
        skillText : '۶۵%'
    },
    {
        skillTitle : 'Product Design',
        skillPercent : 65,
        skillText : '۶۵%'
    },
    {
        skillTitle : 'Blockchain',
        skillPercent : 65,
        skillText : '۶۵%'
    },
    {
        skillTitle : 'Linux terminal',
        skillPercent : 60,
        skillText : '۶۰%'
    },
    {
        skillTitle : 'Mobile native app',
        skillPercent : 45,
        skillText : '۴۵%'
    },
    {
        skillTitle : 'Flutter Web',
        skillPercent : 30,
        skillText : '۳۰%'
    },
]

export const inLearningSkills:Iskill[] = [
    {
        skillTitle : 'React Native',
        skillPercent : 40,
        skillText : '۴۰%'
    },
    {
        skillTitle : 'Prompt Engineer',
        skillPercent : 65,
        skillText : '۶۵%'
    },
    {
        skillTitle : 'Content Creating',
        skillPercent : 40,
        skillText : '۴۰%'
    },
]

export const servicesList:Iservice[] = [
    // {
    //     title : 'ساخت وبسایت فروشگاهی',
    //     description: 'اگر شما صاحب یک بیزینس هستید ، قطعا نیاز به محیطی برای فروش محصولات و همچنین پشتیبانی از مشتریان را حس کرده اید . من میتوانم با هزینه مناسب ، چنین محیطی را بر بستر وبسایت اختصاصی شما ایجاد کنم .'
    // },


]

export const servicesPriceList:IservicePrice[] = [
    {
        title : 'برنامه نویسی سایت',
        price : '170',
        options : [
            'کد نویسی بر اساس UI طراحی شده',
            'اجرای انیمیشن های UI',
            'ریسپانسیو مطابق UI',
            'بارگذاری بر روی هاست',
            'تنظیمات دامنه و DNS',
            'پشتیبانی رایگان پس از اتمام پروژه'
        ],
        // description : 'آماده ثبت سفارش'
    },
    {
        title : 'تدریس برنامه نویسی وب',
        price : '230',
        options : [
            'آموزش اصولی از پایه',
            'ارائه تمرین های واقعی',
            'آموزش مهارت های جانبی',
            'آموزش مهارت های نرم',
            'همراهی در پروسه استخدام',
            'منتورینگ رایگان پس از اتمام آموزش'
        ],
        // description : 'آماده ثبت سفارش'
    },
    {
        title : 'تدریس بازی سازی نوجوانان',
        price : '200',
        options : [
            'آموزش اصولی از پایه',
            'ارائه تمرین های واقعی',
            'معرفی ابزار های جانبی',
            'آموزش ابزار های مورد نیاز',
            'معرفی مسابقات و ...'
        ],
        // description : 'آماده ثبت سفارش'
    },
]

export const jobEx:Ihistory[] = [
    {
        title : 'مکتب شریف',
        date : 'بهمن 1401 - اکنون',
        position : 'Frontend Developer',
        description : 'پیاده سازی وبسایت لندینگ، پنل کاربران و پنل ادمین با استفاده از Next.js',
        actionText : 'وبسایت مکتب شریف',
        action : 'https://maktabsharif.ir/'
    },
    {
        title : 'مکتب شریف',
        date : 'بهمن 1400 - بهمن 1401',
        position : 'Mentor',
        description : 'منتورینگ ۲ دوره بوتکمپ فرانت اند شامل مباحث مقدماتی و پیشرفته',
        actionText : 'وبسایت مکتب شریف',
        action : 'https://maktabsharif.ir/'
    },
    {
        title : 'لینیفای',
        date : 'بهمن 1400 - شهریور 1401',
        position : 'Full-Stack developer',
        description : 'پیاده سازی وبسایت لینیفای شامل لندینگ، فروشگاه، داشبورد کاربر، بلاگ و ... بر پایه NextJs , ExpressJs , MongoDB',
        actionText : 'مشاهده وبسایت',
        action : 'https://leanify.ir/'

    },
    {
        title : 'Kiwi Bit',
        date : 'آبان 1401',
        position : 'UI Designer',
        description : 'طراحی لندینگ وبسایت KiwiBit',
        actionText : 'مشاهده وبسایت',
        action : 'https://kiwibit.io/'

    },
    {
        title : 'Kiwi Bit',
        date : 'بهمن 1400 - آبان 1401',
        position : 'Front-End developer',
        description : 'پیاده سازی وبسایت شبکه اجتماعی کیوی بیت شامل اکانت کاربری، داشبورد، وبلاگ و ... بر پایه NextJs',
        actionText : 'مشاهده وبسایت',
        action : 'https://kiwibit.io/home'
    },
    {
        title : 'نایزک',
        date : 'شهریور 1400 - اسفند 1400',
        position : 'Front-end developer',
        description : 'فعالیت تیمی بر روی پروژه E-commerce با استفاده از ReactJs و Storybook',

    },
    {
        title : 'مکتب شریف',
        date : 'دی 1399 - شهریور 1400',
        position : 'Intern : Front-end developer',
        description : 'گذراندن دوره بوتکمپ 360 ساعته React که علاوه بر آموزش و ارتقای سطح ، شامل کارآموزی نیز می شود .',
        actionText : 'پروژه',
        action : 'https://pc-market-alikooshesh.vercel.app/'
    },
    {
        title : 'XOtrader',
        date : 'تیر 1399 - خرداد 1400',
        position : 'Blockchain Researcher',
        description : 'پروژه ربات هوشمند تریدر XO'
    },
    {
        title : 'فیگ تین',
        date : 'اردیبهشت 1399 - اردیبهشت 1400',
        position : 'Social media manager',
        description : 'مدیریت صفحه اینستاگرام شامل مدیریت تبلیغات ، تنظیم محتوا و پشتیبانی مشتریان',
        actionText : 'اینستاگرام',
        action : 'https://www.instagram.com/figtin_ir/'
    },
    {
        title : 'استهبان تخفیف',
        date : 'دی 1397 - اردیبهشت 1398',
        position : 'موسس و سوشال مدیا مارکتر',
        description : 'مدیریت صفحات مجازی استهبان تخفیف در بستر های اینستاگرام و تلگرام و پشتیبانی کاربران در صفحات مجازی و وبسایت',
        actionText : 'اینستاگرام',
        action : 'https://www.instagram.com/est_takhfif/'
    }
]

export const eduEx:Ihistory[] = [
    {
        title : 'دوره آموزشی MERN stack',
        position : 'کار آموز',
        date : 'فروردین 1401 - اسفند 1401',
        description : 'دوره حرفه ای برنامه نویسی وب - دانشگاه شیراز'
    },
    {
        title : 'دوره آموزشی طراحی محصول',
        position : 'کار آموز',
        date : 'اردیبهشت 1401 - تیر 1401',
        description : 'دوره مقدماتی تا حرفه ای طراحی محصول - وایر فریم - تست - UI UX - آکادمی دیزاینا'
    },
    {
        title : 'بوتکمپ برنامه نویسی',
        position : 'کار آموز',
        date : 'دی 1399 - تیر 1400',
        description : 'بوتکمپ آموزشی Front-end - دوره 49 مکتب شریف'
    },
    {
        title : 'کارشناسی امور تربیتی',
        position : 'دانشجو',
        date : 'مهر 1398 - مهر 1402',
        description : 'کارشناسی امور تربیتی -گرایش آموزش ابتدایی- دانشگاه فرهنگیان'
    }
]

export const portfolioList:Iportfolio[] = [
    {
        id : 'maktab-home',
        title : 'وبسایت مکتب شریف',
        imgLink : 'https://panel.maktabsharif.ir/MAKTAB2.png',
        description : 'پیاده سازی وبسایت لندینگ مکتب شریف',
        actionBtn : "مشاهده وبسایت",
        actionLink : "https://maktabsharif.ir/",
        information : [
            {
                title : 'سبک پروژه',
                value : 'Landing'
            },
            {
                title : 'شیوه همکاری',
                value : 'کارمندی'
            },
            {
                title : 'مسئولیت',
                value : 'Front-end Developer'
            },
            {
                title : 'کارفرما',
                value : 'مکتب شریف'
            },
            {
                title : 'موقعیت',
                value : 'ایران'
            }
        ]
    },
    {
        id : 'maktab-user-panel',
        title : 'پنل کاربر مکتب شریف',
        imgLink : 'https://panel.maktabsharif.ir/MAKTAB2.png',
        description : 'پیاده سازی پنل کاربر مکتب شریف',
        actionBtn : "مشاهده وبسایت",
        actionLink : "https://panel.maktabsharif.ir/",
        information : [
            {
                title : 'سبک پروژه',
                value : 'Dashboard'
            },
            {
                title : 'شیوه همکاری',
                value : 'کارمندی'
            },
            {
                title : 'مسئولیت',
                value : 'Front-end Developer'
            },
            {
                title : 'کارفرما',
                value : 'مکتب شریف'
            },
            {
                title : 'موقعیت',
                value : 'ایران'
            }
        ]
    },
    {
        id : 'maktab-home',
        title : 'وبسایت لینیفای',
        imgLink : 'https://cdn.landin.ir/images/ea7cb127-0d34-4107-96b3-7ee635034bec/0f5a25fc-af41-41bf-a536-b9f02c769de3.png',
        description : 'پیاده سازی وبسایت لندینگ لینیفای',
        actionBtn : "مشاهده وبسایت",
        actionLink : "https://www.leanify.ir/",
        information : [
            {
                title : 'سبک پروژه',
                value : 'Landing'
            },
            {
                title : 'شیوه همکاری',
                value : 'فریلنسری'
            },
            {
                title : 'مسئولیت',
                value : 'Full-stack Developer'
            },
            {
                title : 'کارفرما',
                value : 'لینیفای'
            },
            {
                title : 'موقعیت',
                value : 'ایران'
            }
        ]
    },

    // {
    //     id : 'dabdoob',
    //     title : 'پروژه DABDOOB',
    //     imgLink : 'https://drive.google.com/uc?id=1Idh-adaaAw-Xyn8r_FTRRjcAEs0gvKTB',
    //     description : '',
    //     information : [
    //         {
    //             title : 'سبک پروژه',
    //             value : 'E-Commerce'
    //         },
    //         {
    //             title : 'شیوه همکاری',
    //             value : 'کارمندی'
    //         },
    //         {
    //             title : 'مسئولیت',
    //             value : 'Front-end Developer'
    //         },
    //         {
    //             title : 'کارفرما',
    //             value : 'نایزک'
    //         },
    //         {
    //             title : 'موقعیت',
    //             value : 'کویت'
    //         }
    //     ]
    // }
]

export const routeList:Iroute[] = [
    {
        title : 'خانه',
        address : '/'
    },
    {
        title : 'سوابق کاری و تحصیلی',
        address : '/history'
    },
    {
        title : 'نمونه کار',
        address : '/portfolio'
    }
]