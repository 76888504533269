import React from "react";
import CircleBar from "./skillBarModes/circleBar";
import Check from "./skillBarModes/check";
import LineBar from "./skillBarModes/lineBar";

export interface skillBarProps {
    mode?: 'lineBar' | 'circleBar' | 'checkIcon',
    title?: string,
    text?: string,
    value?: number,
    className?: string
}

const SkillBar:React.FC<skillBarProps> = ({mode , title, text , value , className , children})=> {
    return(
        <>
            {mode === 'circleBar' &&
                <CircleBar text={`${text}`} value={value || 0} title={title} className={className}/>}
            {mode === 'checkIcon' &&
            <Check title={title} className={className}/>}
            {mode === 'lineBar' &&
            <LineBar title={title} value={value || 0} text={text} className={className}/>}
        </>
    )
}

export default SkillBar