import React from "react";

export interface stepperProps{
    isLast? : boolean
}

const Stepper:React.FC<stepperProps> = ({isLast , children}) => {
    return(
        <div className={`${!children && 'hidden'} w-full flex justify-end items-start border-r-4 border-myGray-800 relative p-0 pr-10`}>
            <div className={'w-4 h-4 rounded-full flex justify-center items-center bg-myYellow text-transparent absolute top-1 -right-2.5 animate-pulse'}>
                <div className={'w-2 h-2 rounded-full bg-myGray-500 z-30'}>

                </div>
            </div>
            <div className={`${!isLast && 'pb-8'} w-full h-auto`}>
                <div className={'w-full h-auto p-3 self-end bg-myWhite-100 dark:bg-myGray-200 relative stepperChildBox'}>
                    {children}
                </div>
            </div>
        </div>
    )
}

export default Stepper