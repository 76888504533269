import React from "react";
import {BsCheckLg} from "react-icons/all";

export interface checkProps{
    title?:string
    className?: string
}
const Check:React.FC<checkProps> = (props)=>{
    return(
        <div className={`flex items-center ${props.className}`}>
            <BsCheckLg className={'text-myYellow text-xs'}/>
            <p className={'mr-3 font-estedad text-lg tracking-wider'}>{props.title}</p>
        </div>
    )
}

export default Check