import React from "react";

export interface lineBarProps{
    value: number,
    title?: string,
    text?: string,
    className?: string
}

const LineBar:React.FC<lineBarProps> = (props)=>{
    return(
        <div className={props.className}>
            <div className={'w-full flex justify-between items-center font-estedad mb-1'}>
                <p>{props.title}</p>
                <p className={'text-xs'}>{props.text}</p>
            </div>
            <div className={'w-full flex'}>
                <div className={'bg-myYellow h-1'} style={{width : `${props.value}%`}}/>
                <div className={'bg-black h-1'} style={{width : `${100 - props.value}%`}}/>
            </div>
        </div>
    )
}

export default LineBar