import {Iservice} from "../../interface/dataInterface";
import React from "react";

export interface servicesCardProps extends Iservice{

}

const ServicesCard:React.FC<servicesCardProps> = (props) => {
    return(
        <div className={'w-full h-full shadow-xl p-8 bg-myWhite-300 dark:bg-myGray-400 font-estedad cursor-pointer scale-125'}>
            <h5 className={'text-lg font-black mb-4'}>{props.title}</h5>
            <p className={'font-thin text-gray-300'}>{props.description}</p>
            <button className={'w-100 mt-3 text-sm flex items-center text-myYellow'}>
                <span className={'ml-3'}>سفارش پروژه</span>
                <span className={'text-lg pt-1'}>{'>'}</span>
            </button>
        </div>
    )
}

export default ServicesCard