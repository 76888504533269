import React from "react";
import PortfolioCard from "../../components/portfolioCard";
import {portfolioList} from "../../data/data";

export interface portfolioProps{

}

const Portfolio:React.FC<portfolioProps> = () => {
    return (
        <div className={'w-full max-w-full px-5 lg:px-10 py-10 flex flex-wrap justify-center gap-4'} data-aos="fade-up">
            {portfolioList.map(item => {
                return(
                    <div className={'w-96 md:w-72 border border-transparent rounded-lg hover:border-myYellow'}>
                        <PortfolioCard {...item}/>
                    </div>
                )
            })}
        </div>
    )
}

export default Portfolio