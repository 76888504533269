import React from "react";
import {eduEx, jobEx} from "../../data/data";
import HistoryCard from "../../components/historyCard";

export interface historyProps{}

const History:React.FC<historyProps> = () => {
    return(
        <div className={'w-full max-w-full px-5 lg:px-10 py-10 flex flex-col xl:flex-row justify-center items-center xl:items-start'} data-aos="fade-up">
            <div className={'w-full lg:w-3/4 xl:w-1/2 flex flex-col justify-center items-center xl:pl-3'}>
                <p className={'w-full font-mikhak font-semibold text-2xl text-right mb-6 text-myWhite-100'}>سوابق کاری</p>
                {jobEx.map((item,index,array)=>{
                    return(
                        <HistoryCard key={`${index}-job`} title={item.title} date={item.date} position={item.position}
                                     description={item.description} action={item.action} actionText={item.actionText}
                                     isLast={index === array.length - 1 && true}/>
                    )
                })}
            </div>

            <div className={'w-full lg:w-3/4 xl:w-1/2 flex flex-col justify-center items-center mt-12 xl:mt-0 xl:pr-3'}>
                <p className={'w-full font-mikhak font-semibold text-2xl text-right mb-6 text-myWhite-100'}>دوره های گذرانده شده</p>
                {eduEx.map((item,index,array)=>{
                    return(
                        <HistoryCard key={`${index}-job`} title={item.title} date={item.date} position={item.position}
                                     description={item.description} action={item.action} actionText={item.actionText}
                                     isLast={index === array.length - 1 && true}/>
                    )
                })}
            </div>
        </div>
    )
}

export default History