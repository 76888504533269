import React from "react";
import {Ihistory} from "../../interface/dataInterface";
import Stepper from "../stepper";

export interface historyCardProps extends Ihistory{
    isLast?: boolean
}

const HistoryCard:React.FC<historyCardProps> = (props) => {
    return(
        <Stepper isLast={props.isLast}>
            <div className={'w-full font-estedad mobile:pb-2'}>
                <div className={'w-full mb-2'}>
                    <p className={'font-estedad-normal font-bold text-lg text-myWhite-100'}>{props.title}</p>
                    <p className={'text-sm text-myWhite-500 opacity-50'}>{props.position}</p>
                </div>
                <p className={`${!props.description && 'hidden'} w-full mb-2 text-sm`}>{props.description}</p>
                <div className={'w-full flex mobile:flex-col notMobile:items-center justify-between'}>
                    <button className={`${(!props.action || !props.actionText) && 'opacity-0 mobile:hidden'} text-s font-bold p-2 rounded-lg
                    text-myYellow mt-3 mobile:mt-3 mobile:pb-2 mobile:order-2`}>
                        <a href={props.action} target={'_blank'} rel={"noreferrer"}>
                            <span className={'pl-2'}>{props.actionText}</span>
                            <span>{'>'}</span>
                        </a>
                    </button>

                    <div className={'p-3 rounded-full bg-myGray-500 text-sm opacity-75 mobile:mt-2'}>
                        <p className={'text-center'}>{props.date}</p>
                    </div>
                </div>
            </div>
        </Stepper>
    )
}

export default HistoryCard