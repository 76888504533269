import React from "react";
import {IservicePrice} from "../../interface/dataInterface";
import {BsCheckLg} from "react-icons/all";

export interface servicesPriceCardProps extends IservicePrice{

}
const ServicesPriceCard:React.FC<servicesPriceCardProps> = (props) => {
    return(
        <div className={'w-full h-full shadow-xl p-8 bg-myWhite-300 dark:bg-myGray-400 font-estedad cursor-pointer scale-125'}>
            <a href={'mailto:alikooshesh007@gmail.com'} target={"_blank"} className={'w-full h-full flex flex-col justify-between'}>
                <div>
                    <h5 className={'w-full text-center text-lg font-black mb-3'}>{props.title}</h5>
                    <p className={'font-mikhak text-center mb-10 items-center'}>
                        {/*<span className={'text-myYellow text-xl font-black pl-2'}>{props.price}</span>*/}
                        {/*<span className={'text-gray-400'}>هزار تومان / هر ساعت</span>*/}
                    </p>
                    {props.options.map((item,index) => {
                        return(
                            <p key={`ServicesPriceCard-option-${item}-${index}`} className={'text-center font-bold flex items-center justify-start mb-2'}>
                                <BsCheckLg className={'text-myYellow text-xs'}/>
                                <p className={'text-gray-300 mr-3'}>{item}</p>
                            </p>
                        )
                    })}
                </div>
                <button className={'w-full mt-10 text-sm flex items-center justify-center text-myYellow font-black'}>
                    <span className={'ml-3'}>تماس با من</span>
                    <span className={'text-lg pt-1'}>{'>'}</span>
                </button>
                {props.description ? <div className={'w-full text-center justify-center'}>
                    <small className={'w-full text-center text-gray-300 text-center'}>{props.description || ' '}</small>
                </div> : <></>}
            </a>
        </div>
    )
}

export default ServicesPriceCard