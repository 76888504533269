import {CircularProgressbar} from "react-circular-progressbar";
import 'react-circular-progressbar/dist/styles.css';
import React from "react";

export interface circleBarProps{
    title?: string,
    text : string,
    value : number,
    className?: string
}

const CircleBar:React.FC<circleBarProps> = ({title , text , value , className , children}) => {
    return(
        <div className={className}>
            <CircularProgressbar value={value} text={text}/>
            <p className={'pt-2 text-center'}>{title}</p>
        </div>
    )
}

export default CircleBar