import React from "react";
import './style.css'
import ServicesCard from "../../components/servicesCard";
import {servicesList, servicesPriceList} from "../../data/data";
import ServicesPriceCard from "../../components/servicesPriceCard";
import TypeWriter from 'typewriter-effect'
import {Link} from "react-router-dom";
import BannerImage from '../../files/images/pexels-josh-sorenson-1714208.jpg'

const HomePage:React.FC = ()=>{
    return(
        <div className={'w-full max-w-full px-5 lg:px-10 py-10'} data-aos="fade-up">
            <section className={'w-full h-80 flex justify-between items-center flex-wrap relative bg-myWhite-100 dark:bg-myGray-200'}>
                <div className={'home--header--bgImageContainer w-full h-full absolute z-10 opacity-30 scale-150'} style={{backgroundImage : `url(${BannerImage})`}}/>
                <div className={'w-full h-full z-20 px-4 py-16 lg:px-16'}>
                    <div className={'w-full items-center lg:w-8/12 lg:items-start h-full flex flex-col justify-between items-start'}>
                        <p className={'font-mikhak text-4xl font-black text-center lg:text-right'}>من اینجا هستم ...</p>
                        <p className={'font-estedad text-lg items-center'}>
                            <span className={'ml-1'}>
                                <span>{'<'}</span>
                                <span className={'text-myYellow'}>Code</span>
                                <span>{'/>'}</span>
                            </span>
                            <span>
                                <span>مهارت در </span>
                                <span className={'font-bold'}>
                                    <TypeWriter
                                        options={{
                                            strings : [
                                                'برنامه نویسی وب',
                                                'زبان سالیدیتی',
                                                'تدریس برنامه نویسی'
                                            ],
                                            loop : true,
                                            autoStart : true
                                        }}
                                    />
                                </span>
                            </span>
                            <span className={'pr-1'}>
                                <span>{'<'}</span>
                                <span className={'text-myYellow'}>Code</span>
                                <span>{'>'}</span>
                            </span>
                        </p>

                        <Link to={'/portfolio'}>
                            <button className={'font-mikhak text-center px-5 py-3 outline-none border-0 ring-transparent bg-myYellow text-black '}>مشاهده نمونه کار ها</button>
                        </Link>
                    </div>
                </div>
            </section>

            <section className={'w-full mt-8 flex justify-between items-center flex-wrap'}>
                <div className={'flex items-center justify-end text-center'}>
                    <span className={'text-myYellow font-mikhak text-2xl font-bold pl-2'}>6+</span>
                    <span className={'text-myGray-400 dark:text-myWhite-200 font-mikhak font-light'}>سال سابقه کار</span>
                </div>

                <div className={'flex items-center justify-end text-center hidden lg:block'}>
                    <span className={'text-myYellow font-mikhak text-2xl font-bold pl-2'}>8+</span>
                    <span className={'text-myGray-400 dark:text-myWhite-200 font-mikhak font-light'}>پروژه انجام شده</span>
                </div>

                <div className={'flex items-center justify-end text-center'}>
                    <span className={'text-myYellow font-mikhak text-2xl font-bold pl-2'}>4+</span>
                    <span className={'text-myGray-400 dark:text-myWhite-200 font-mikhak font-light'}>مشتری خرسند</span>
                </div>

                <div className={'flex items-center justify-end text-center hidden xl:block'}>
                    <span className={'text-myYellow font-mikhak text-2xl font-bold pl-2'}>4+</span>
                    <span className={'text-myGray-400 dark:text-myWhite-200 font-mikhak font-light'}>سال تجربه کاری مرتبط</span>
                </div>
            </section>
            {servicesList.length > 0 ?
                <section className={'w-full mt-8 flex flex-wrap justify-center items-center'}>
                    {servicesList.map((item , index) =>
                        <div key={`serviceCard-${index}`} className={'w-full sm:w-5/12 md:w-10/12 lg:w-64 xl:w-80 my-5 mx-2'}>
                            <ServicesCard title={item.title} description={item.description}/>
                        </div>)}
                </section> :<></>
            }
            <section className={'mt-8'}>
                <h3 className={'w-full mb-3 font-estedad text-2xl font-black text-center sm:text-right'}>تعرفه خدمات</h3>
                <div className={'w-full h-auto flex flex-wrap justify-center'}>
                    {servicesPriceList.map((item , index) =>
                        <div key={`serviceCard-${index}`} className={'w-full sm:w-5/12 md:w-10/12 lg:w-64 xl:w-80 my-5 mx-2 flex-1'}>
                            <ServicesPriceCard title={item.title} price={item.price} options={item.options} description={item.description ?? undefined}/>
                        </div>)}
                </div>
            </section>
        </div>
    )
}

export default HomePage