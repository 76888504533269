import React, {useEffect, useState} from "react";
import './style/layoutStyles.css'
import {
    inLearningSkills,
    languageSkills,
    mainSkills,
    profilePicFileLink,
    routeList,
    secondarySkills
} from "../data/data";
import SkillBar from "../components/skillBar";
import {
    BsDownload,
    BsLinkedin,
    BsList,
    FaGit,
    FaWhatsapp,
    SiMaildotru,
} from "react-icons/all";
import classNames from "classnames";
import {Link, Outlet, useLocation} from "react-router-dom";
// @ts-ignore
import aos from 'aos/dist/aos'


export interface BaseLayoutProps {
}

const BaseLayout: React.FC<BaseLayoutProps> = () => {

    const location = useLocation()

    const [sideMode, setSideMode] = useState<'l' | 'lr' | 'r'>('lr')

    const leftSideClasses = classNames(
        (sideMode === 'lr' || sideMode === 'r') ? 'w-0 lg:w-1/12' :
            'w-1/4 2xl:w-1/5 leftside--l',
        'h-full hidden lg:block bg-myWhite-300 dark:bg-myGray-400 myTransitionWidth overflow-hidden',
        'absolute top-0 left-0 z-50'
    )

    const rightSideClasses = classNames(
        (sideMode === 'lr' || sideMode === 'r') ? 'min-w-75 md:w-6/12 xl:w-1/3' :
            'md:w-1/12',
        'hidden md:block h-full bg-myWhite-300 dark:bg-myGray-400 drop-shadow-xl myTransitionWidth overflow-hidden z-50'
    )

    useEffect(()=>{
        aos.init()
    },[])

    return (
        <div
            className={'w-full h-full xl:py-4 flex justify-center align-start bg-myWhite-500 dark:bg-myGray-600 text-black dark:text-white'}>
            <div className={'w-full h-full flex xl:w-11/12 2xl:w-10/12 bg-myWhite-400 dark:bg-myGray-500'}>
                {/*Right side*/}
                <div className={rightSideClasses}>
                    <div
                        className={`w-full h-1/4 xl:h-1/3 flex flex-col items-center justify-center drop-shadow-xl max-h-750:h-1/3 ${sideMode !== 'l' && 'bg-myWhite-100 dark:bg-myGray-200'}`}>
                        <div
                            className={`${sideMode === 'l' ? 'w-16 h-16 xl:w-20 xl:h-20' : 'w-20 h-20 xl:w-32 xl:h-32'} rounded-full relative myTransitionAll`}>
                            <img className={'w-full h-full rounded-full'} src={profilePicFileLink}
                                 alt={'Ali Kooshesh Profile'}/>
                            <div
                                className={'w-4 h-4 rounded-full bg-myYellow text-transparent absolute bottom-1 right-2'}>.
                            </div>
                            <div
                                className={'w-4 h-4 rounded-full bg-myYellow text-transparent absolute bottom-1 right-2 animate-ping'}>.
                            </div>
                        </div>
                        <div className={`text-center myTransitionAll ${sideMode === 'l' && 'myRotate90'}`}>
                            <h3 className={`${sideMode === 'l' ? 'text-lg mr-20 min-w-110px text-center' : 'mt-5 text-2xl'} font-mikhak font-bold hover:text-myYellow cursor-pointer`}>علی
                                کوشش</h3>
                            <p className={`mt-1 text-lg font-estedad ${sideMode === 'l' && 'hidden'}`}>توسعه دهنده
                                وب</p>
                        </div>
                    </div>

                    <div
                        className={`w-full h-3/4 xl:h-2/3 flex flex-col items-center p-5 bg-transparent overflow-y-auto overflow-x-hidden scrollbar-hide max-h-750:h-2/3 ${sideMode === 'l' && 'hidden opacity-0'}`}>
                        <section
                            className={'w-full text-black dark:text-white pb-6 mb-6 border-b border-myWhite-600 border-opacity-30'}>
                            <div className={'flex justify-between items-center'}>
                                <p className={'font-mikhak text-right text-sm min-w-110px'}>محل سکونت :</p>
                                <p className={'font-estedad text-left text-sm font-semibold min-w-110px'}>ایران /
                                    فارس</p>
                            </div>
                            <div className={'mt-2 flex justify-between items-center'}>
                                <p className={'font-mikhak text-right min-w-110px'}>سال تولد :</p>
                                <p className={'font-estedad text-left text-sm font-semibold min-w-110px'}>۱۳۷۹</p>
                            </div>
                            <div className={'mt-1 flex justify-between items-center'}>
                                <p className={'font-mikhak text-right min-w-110px'}>وضعیت سربازی :</p>
                                <p className={'font-estedad text-left text-sm font-semibold min-w-110px'}>معافیت
                                    تحصیلی</p>
                            </div>
                        </section>
                        {/*<section*/}
                        {/*    className={'w-full flex justify-start items-center text-black dark:text-white pb-6 mb-6 border-b border-myWhite-600 border-opacity-30'}>*/}
                        {/*    {languageSkills.map((item, index) => {*/}
                        {/*        return (*/}
                        {/*            <div key={`languageSkills-${index}`}*/}
                        {/*                 className={'w-16 h-18 flex flex-col items-center font-estedad font-bold text-sm ml-3'}>*/}
                        {/*                <SkillBar mode={'circleBar'} value={item.skillPercent} title={item.skillTitle}*/}
                        {/*                          text={item.skillText}/>*/}
                        {/*            </div>*/}
                        {/*        )*/}
                        {/*    })}*/}
                        {/*</section>*/}

                        <section
                            className={'w-full text-black dark:text-white pb-6 mb-6 border-b border-myWhite-600 border-opacity-30'}>
                            <div>
                                <h3 className={'font-mikhak text-lg font-medium mb-4'}>مهارت های اصلی من :</h3>
                                {mainSkills.map((item, index) => {
                                    return (
                                        <div key={`mainSkills-${index}`} className={'mb-1'}>
                                            <SkillBar mode={'checkIcon'} title={item.skillTitle}/>
                                        </div>
                                    )
                                })}
                            </div>
                        </section>

                        <section
                            className={'w-full text-black dark:text-white pb-6 mb-6 border-b border-myWhite-600 border-opacity-30'}>
                            <h3 className={'font-mikhak text-lg font-medium mb-5'}>مهارت های فرعی که در حد نیاز یاد
                                گرفتم :</h3>
                            {secondarySkills.map((item, index) => {
                                return (
                                    <div key={`secondarySkills-${index}`} className={'mb-4'}>
                                        <SkillBar key={`secondarySkills-${index}`}
                                                  mode={'lineBar'} title={item.skillTitle} value={item.skillPercent}
                                                  text={item.skillText}/>
                                    </div>
                                )
                            })}
                        </section>

                        <section
                            className={'w-full text-black dark:text-white pb-6 mb-6 border-b border-myWhite-600 border-opacity-30'}>
                            <h3 className={'font-mikhak text-lg font-medium mb-5'}>مهارت هایی که دارم یادشون میگیرم
                                :</h3>
                            <div className={'w-full flex justify-around items-center'}>
                                {inLearningSkills.map((item, index) => {
                                    return (
                                        <div key={`inLearningSkills-${index}`}
                                             className={'w-16 h-18 flex flex-col items-center font-estedad font-bold text-sm'}>
                                            <SkillBar mode={'circleBar'} value={item.skillPercent}
                                                      title={item.skillTitle} text={item.skillText}/>
                                        </div>
                                    )
                                })}
                            </div>
                        </section>

                        <section className={'w-full text-black dark:text-white pb-6'}>
                            <div className={'flex justify-center items-center hover:text-myYellow cursor-pointer'}>
                                <BsDownload className={'text-lg'}/>
                                <p className={'pr-2 font-mikhak font-light'}>
                                    <a href={'https://drive.google.com/uc?id=1GrmxOQ8wz9FtbhNP_5tB9yjyISSvE6pf&export=download'}
                                       target={'_blank'} rel={'noreferrer'}>
                                        دانلود رزومه من
                                    </a>
                                </p>
                            </div>
                        </section>
                    </div>
                </div>


                <div className={'w-full h-full relative'}>
                    <div
                        className={`w-full h-full bg-myGray-700 opacity-70 absolute top-0 right-0 z-30 ${sideMode !== 'lr' ? 'block' : 'hidden'}`}
                        onClick={() => sideMode !== 'lr' && setSideMode('lr')}/>

                    {/*Main*/}
                    <main className={'w-full h-full lg:w-11/12 z-20 overflow-y-auto overflow-x-hidden scrollbar-hide'}>
                        <Outlet/>
                    </main>

                    {/*Left Side*/}
                    <div className={leftSideClasses}>
                        <div
                            className={'w-full h-20 flex justify-center items-center bg-myWhite-100 dark:bg-myGray-200'}>
                            <BsList className={'text-5xl text-myGray-400 dark:text-myWhite-200 cursor-pointer'}
                                    onClick={() => {
                                        sideMode !== 'l' ?
                                            setSideMode('l') :
                                            setSideMode('lr')
                                    }}/>
                        </div>

                        <div className={'w-100 h-5/6 py-5 px-2 flex flex-col justify-between'}>
                            {sideMode === 'l' ?
                                <div className={'w-full h-full'}>
                                    <div
                                        className={'w-full h-full pr-6 flex flex-col justify-center items-start text-myGray-400 dark:text-myWhite-200'}>
                                        {routeList.filter((item) => item.address === location.pathname).map((item) => {
                                            return (
                                                <Link key={`route-${item.address}`} to={item.address}
                                                      onClick={() => setSideMode("lr")}>
                                                    <button className={`pb-10 font-estedad text-lg text-myYellow`}>
                                                        <span className={'pl-2 whitespace-nowrap'}>{item.title}</span>
                                                        <span>{'>'}</span>
                                                    </button>
                                                </Link>
                                            )
                                        })}
                                        {routeList.filter((item) => item.address !== location.pathname).map((item) => {
                                            return (
                                                <Link key={`route-${item.address}`} to={item.address}
                                                      onClick={() => setSideMode("lr")}>
                                                    <button className={`pb-3 font-estedad text-lg hover:text-myYellow`}>
                                                        <span className={'pl-2 whitespace-nowrap'}>{item.title}</span>
                                                        <span>{'>'}</span>
                                                    </button>
                                                </Link>
                                            )
                                        })}
                                    </div>
                                </div> :
                                <p className={'font-mikhak opacity-50 myRotate90 text-center whitespace-nowrap pr-8'}>{(routeList.find(item => item.address === location.pathname))?.title || "خانه"}</p>}
                            <div
                                className={`w-100 flex ${sideMode !== 'l' ? 'flex-col' : 'justify-around'} items-center text-2xl text-myGray-400 dark:text-myWhite-200 cursor-pointer`}>
                                <a rel="noreferrer" href={'https://www.linkedin.com/in/alikooshesh/'} target={'_blank'}>
                                    <BsLinkedin title={'LinkedIn : AliKooshesh'}
                                                className={'hover:text-myYellow mb-3'}/>
                                </a>
                                <a rel="noreferrer" href={'https://github.com/Alikooshesh'} target={'_blank'}>
                                    <FaGit title={'Github : Alikooshesh'} className={'hover:text-myYellow mb-3'}/>
                                </a>
                                <a rel="noreferrer" href={'mailto:alikooshesh007@gmail.com'} target={'_blank'}>
                                    <SiMaildotru title={'Email : Alikooshesh007@gmail.com'}
                                                 className={'hover:text-myYellow mb-3'}/>
                                </a>
                                <a rel="noreferrer" href={'https://wa.me/989362766356'} target={'_blank'}
                                   className={'pb-5'}>
                                    <FaWhatsapp title={'Whatsapp : +989362766356'} className={'hover:text-myYellow'}/>
                                </a>
                            </div>
                        </div>

                        <div
                            className={`w-full flex flex-col items-center text-myGray-400 dark:text-myWhite-200 border-myGray-400 dark:border-myWhite-200 cursor-pointer max-h-750:hidden`}>
                            <button
                                className={`${sideMode !== 'l' && 'max-h-901:hidden myRotate90'} font-mikhak border rounded p-1 hover:bg-myYellow hover:border-myYellow hover:text-myGray-400 font-medium myTransitionAll`}>تماس
                                فوری
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BaseLayout