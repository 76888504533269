import React from "react";
import {Iportfolio} from "../../interface/dataInterface";

export interface portfolioCardProps extends Iportfolio{

}

const PortfolioCard:React.FC<portfolioCardProps> = (props) => {
    return(
        <div className={'w-full h-full rounded-lg shadow-lg p-4 pb-8 bg-myWhite-300 dark:bg-myGray-400 font-estedad cursor-pointer'}>
            <a href={props.actionLink} target={"_blank"} className={'w-full h-full flex flex-col justify-between'}>
                <div className={'rounded-xl bg-myWhite-200 dark:bg-myGray-500 p-4'}>
                    <img className={'w-full h-auto rounded-t'} src={props.imgLink} alt={props.title}/>
                </div>
                <div>
                    <p className={'w-full text-lg font-black mt-4'}>{props.title}</p>
                    <button className={'w-100 mt-3 text-sm flex items-center text-myYellow'}>
                        <span className={'ml-3'}>مشاهده جزئیات</span>
                        <span className={'text-lg pt-1'}>{'>'}</span>
                    </button>
                </div>
            </a>
        </div>
    )
}

export default PortfolioCard