import React from 'react';
import './App.css';
import BaseLayout from "./baseLayout";
import History from "./pages/history";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import HomePage from "./pages/home";
import Portfolio from "./pages/portfolio";

function App() {
  return (
    <BrowserRouter>
        <div className={'w-full h-full break-words dark'}>
            <Routes>
                <Route path="/" element={<BaseLayout />}>
                    <Route index element={<HomePage />} />
                    <Route path="history" element={<History />} />
                    <Route path="portfolio" element={<Portfolio />} />
                    <Route path="*" element={<HomePage />} />
                </Route>
            </Routes>
        </div>
    </BrowserRouter>
  );
}

export default App;
